import * as React from "react";
import { cn } from "../lib/utils";

const SidebarMenu = React.forwardRef(({ className, type, ...props }, ref) => {
  return (
    // Return with the innerHTML of the sidebar
    <aside
      className={cn(
        "flex flex-col space-y-1 p-4 text-sm border-[1px] border-transparent border-r-[#E4E4E7] dark:border-r-[#27272A] w-[288px] h-full",
        className
      )}
      {...props}
    >
      {props.children}
      <a href="/privacy" className="flex items-center space-x-2">
        <span className="text-gray-500 dark:text-gray-400">Privacy</span>
      </a>
    </aside>
  );
});
SidebarMenu.displayName = "SidebarMenu";

export { SidebarMenu };
